'use client';

import { Website } from '@/websites/actions/fetchWebsites';
import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';

const WebsiteThumbnail = ({ website }: { website: Website }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const sizes = '(min-width: 1920px) 16.666vw, (min-width: 1024px) 33.333vw, (min-width: 640px) 50vw, 100vw';

  const src =
    website.versions?.[0]?.imageData &&
    typeof website.versions[0].imageData === 'object' &&
    'id' in website.versions[0].imageData
      ? `/${(website.versions[0].imageData as { id: string }).id}`
      : null;

  return (
    <div className={clsx('w-full relative aspect-[16/10] rounded-sm overflow-hidden')}>
      {!!src && (
        <Image
          src={src ?? ''}
          fill
          sizes={sizes}
          className={clsx(
            'WebsiteCard__image',
            'object-cover object-top overflow-hidden z-10 rounded-sm transition-opacity duration-500 h-full w-full absolute inset-0',
            {
              'opacity-0 not-loaded': !isLoaded,
              'opacity-100': isLoaded,
            },
          )}
          alt={website.title ?? ''}
          onLoad={(e) => {
            setIsLoaded(true);
          }}
          onError={(e) => {
            setIsLoaded(true);
          }}
          draggable={false}
        />
      )}

      {!isLoaded && (
        <div className={clsx('bg-zinc-50 h-full w-full animate-pulse absolute inset-0')}>
          <div className={clsx('flex items-center justify-center h-full')}></div>
        </div>
      )}
    </div>
  );
};

export default WebsiteThumbnail;
