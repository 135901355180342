import clsx from "clsx";

const LoadingDots = ({ size = 1 }) => {
	return (
		<div
			className={clsx("flex", {
				"space-x-1": size === 1,
				"space-x-0.5": size === 0.5,
			})}
		>
			{[0, 1, 2].map((index) => (
				<div
					key={index}
					className={clsx("animate-dots-fade rounded-full bg-current", {
						"size-1": size === 1,
						"size-0.5": size === 0.5,
					})}
					style={{
						animationDelay: `${index * 0.15}s`,
					}}
				></div>
			))}
		</div>
	);
};

export default LoadingDots;
