
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"f268562283d4fc04176da7d0c3125b789de39ce0":"default"} */ export default createServerReference("f268562283d4fc04176da7d0c3125b789de39ce0");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

