export default function cloudflareImageLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  const baseUrl = "https://assets.siteinspire.com";

  // Ensure the src starts with a forward slash
  const imagePath = src.startsWith("/") ? src : `/${src}`;

  // Construct the Cloudflare Image Resizing URL
  const params = [`width=${width}`, `quality=${quality || 75}`, "format=auto"];

  return `${baseUrl}/cdn-cgi/image/${params.join(",")}${imagePath}`;
}
