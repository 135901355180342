import clsx from "clsx";

const WebflowCircle = () => {
  return (
    <div
      className={clsx(
        "size-8 flex items-center justify-center bg-[#146EF5] text-white rounded-full"
      )}
    >
      <svg
        viewBox="0 0 39 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx("w-4 h-4")}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.7475 0L26.4707 24H14.9392L20.077 14.0534H19.8465C15.6078 19.5558 9.28359 23.178 0.272522 24V14.191C0.272522 14.191 6.03712 13.8505 9.42596 10.2877H0.272522V0.000189575H10.56V8.46149L10.7909 8.46054L14.9947 0.000189575H22.7749V8.40784L23.0058 8.40746L27.3673 0H38.7475Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  );
};

export default WebflowCircle;
