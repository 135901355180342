import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import Link from "next/link";

const SelectedLink = () => {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <Link
          href={{
            pathname: "/websites",
            query: { selected: true },
          }}
          passHref
          aria-label="Siteinspire Selected"
          className={clsx("block h-2 w-2 rounded-full bg-red-500")}
        ></Link>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content
          className={clsx("tooltip-content flex flex-col space-y-1")}
          sideOffset={2}
        >
          <div>Siteinspire Selected: one of our favourite websites</div>
          <Link
            href={{
              pathname: "/websites",
              query: { selected: true },
            }}
            className={clsx(
              "underline decoration-zinc-500 underline-offset-2 transition ease-out hover:opacity-75",
            )}
          >
            See more
          </Link>
          <Tooltip.Arrow />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

export default SelectedLink;
