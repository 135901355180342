import type { Website } from '@/websites/actions/fetchWebsites';
import WebflowToken from '@/websites/components/WebflowToken';
import WebsiteCaption from '@/websites/components/WebsiteCaption';
import WebsiteThumbnail from '@/websites/components/WebsiteThumbnail';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relative from 'dayjs/plugin/relativeTime';
import Link from 'next/link';
import React from 'react';

dayjs.extend(relative);

type Props = {
  website: Website;
};

const WebsiteCard = ({ website }: Props) => {
  return (
    <div className={clsx('WebsiteCard', 'group @container')}>
      <div className={clsx('WebsiteCard__content', 'relative')}>
        <WebflowToken
          websiteId={website.id}
          showCaption={false}
          className="absolute right-8 top-8 z-20 @xs:right-12 @xs:top-12"
        />

        {website.url && (
          <Link
            href={website.url}
            className={clsx(
              'WebsiteCard__link',
              'circular-button absolute bottom-8 right-8 z-20 opacity-0 transition-opacity group-hover:opacity-100 @xs:bottom-12 @xs:right-12',
            )}
            target="_blank"
            rel="noreferrer"
          >
            <ArrowUpRightIcon className={clsx('h-3.5 w-3.5')} />
          </Link>
        )}

        <div className={clsx('overflow-hidden')}>
          <Link
            href={`/websites/${website.id}-${website.slug}`}
            className={clsx('WebsiteCard__imageWrapper', 'block rounded bg-zinc-100 p-4 @xs:p-6')}
            draggable={false}
            scroll={false}
          >
            <WebsiteThumbnail website={website} />
          </Link>
        </div>
      </div>
      <WebsiteCaption website={website} />
    </div>
  );
};

export default React.memo(WebsiteCard);
