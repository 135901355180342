import { Website } from "@/websites/actions/fetchWebsites";
import SelectedLink from "@/websites/components/SelectedLink";
import clsx from "clsx";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import Link from "next/link";

dayjs.extend(relative);

type Credit = NonNullable<Website["credits"]>[number];

const WebsiteCaption = ({ website }: { website: Website }) => {
  return (
    <div className={clsx("WebsiteCard__caption", "flex flex-col")}>
      <div className={clsx("flex items-center")}>
        <Link
          href={`/websites/${website.id}-${website.slug}`}
          className={clsx(
            "WebsiteCard__title",
            "flex h-8 flex-1 items-end space-x-2 transition-colors ease-out hover:text-zinc-500",
          )}
        >
          <span>{website.title}</span>
          <span className={clsx("hidden text-zinc-500 @xs:block")}>
            {dayjs(website.publishedAt).fromNow()}
          </span>
        </Link>
        <div className={clsx("")}>{website.isSelected && <SelectedLink />}</div>
      </div>
      {website.credits && website.credits.length > 0 && (
        <div>
          <div className={clsx("flex flex-wrap text-zinc-500")}>
            {(website.credits as Credit[]).flatMap((credit) =>
              (credit.creditExpertises ?? []).map((creditExpertise) => (
                <div
                  key={`${credit.id}-${creditExpertise.id}`}
                  className={clsx("mr-2")}
                >
                  {creditExpertise.expertise.name}
                </div>
              )),
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WebsiteCaption;
