'use client';

import Ghost from '@/components/Ghost';
import LoadingDots from '@/components/LoadingDots';
import fetchWebsites, { Website } from '@/websites/actions/fetchWebsites';
import WebsiteCard from '@/websites/components/WebsiteCard';
import clsx from 'clsx';
import { useInView } from 'framer-motion';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const ENABLE_INFINITE_SCROLL = false;

const Websites = ({
  initialWebsites = [],
  initialOffset = 0,
  limit = 60,
  showGhost = true,
  highlightGhost = false,
  categorySlugs = [],
}: {
  initialWebsites?: Website[];
  initialOffset?: number;
  limit?: number;
  showGhost?: boolean;
  highlightGhost?: boolean;
  categorySlugs?: string[];
}) => {
  const [offset, setOffset] = useState(initialOffset);
  const [websites, setWebsites] = useState(initialWebsites);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(initialWebsites.length === limit);

  // If we receive a new set of initialWebsites, we need to reset the offset and hasMore state, and update the websites state
  useEffect(() => {
    setOffset(initialOffset);
    setWebsites(initialWebsites);
    setHasMore(initialWebsites.length === limit);
  }, [initialWebsites]);

  const ref = useRef(null);
  const isInView = useInView(ref);

  const loadMoreWebsites = useCallback(async () => {
    setIsLoadingMore(true);

    try {
      const { websites: moreWebsites } = await fetchWebsites({
        limit,
        offset,
        categorySlugs,
      });

      if (moreWebsites.length < limit) {
        setHasMore(false);
      }

      setWebsites((prevWebsites) => [...prevWebsites, ...(moreWebsites as Website[])]);
      setOffset((prevOffset) => prevOffset + limit);

      // const page = Math.floor(offset / limit) + 1;
      // window.history.pushState(null, "", `/websites/page/${page}`);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [limit, offset, categorySlugs]);

  useEffect(() => {
    if (!ENABLE_INFINITE_SCROLL) return;

    if (isLoadingMore || !hasMore || !isInView) return;

    loadMoreWebsites();
  }, [isInView, isLoadingMore, hasMore, loadMoreWebsites]);

  // Define a method to decide Ghost component visibility
  const ghostVisibility = useCallback(
    (index: number) => {
      const baseClass = 'col-span-12 sm:col-span-6 lg:col-span-4 3xl:col-span-2';
      const visibilityClasses = [
        'hidden sm:block lg:hidden',
        'block sm:hidden lg:block 2xl:hidden',
        'hidden 2xl:block 3xl:hidden',
        'hidden 3xl:block',
      ];
      const highlightClasses = ['bg-yellow-500', 'bg-blue-500', 'bg-green-500', 'bg-red-500'];

      if (index >= 0 && index < 4) {
        return clsx(baseClass, visibilityClasses[index], highlightGhost && highlightClasses[index]);
      }
      return 'hidden';
    },
    [highlightGhost],
  );

  // Memoize the rendered website cards
  const memoizedWebsiteCards = useMemo(() => {
    return websites.map((website, _index) => (
      <Fragment key={website.id}>
        <div className={clsx('col-span-12 sm:col-span-6 lg:col-span-4 3xl:col-span-2')}>
          <WebsiteCard website={website} />
        </div>

        {showGhost && (
          <div key={`${website.id}-ghost`} className={ghostVisibility(_index)}>
            <Ghost />
          </div>
        )}
      </Fragment>
    ));
  }, [websites, showGhost, highlightGhost]); // Dependencies for memoization

  return (
    <div className={clsx('Websites', 'px-8')}>
      <div
        className={clsx('Websites__content', 'grid grid-cols-12 gap-x-8 gap-y-8 lg:gap-x-16 lg:gap-y-12 2xl:gap-x-16')}
      >
        {memoizedWebsiteCards}
        {hasMore && (
          <button
            className={clsx('col-span-12 flex items-start sm:col-span-6 lg:col-span-4 3xl:col-span-2')}
            onClick={loadMoreWebsites}
            ref={ref}
          >
            <div className={clsx('block w-full rounded border bg-white p-6')}>
              <div
                className={clsx('relative flex aspect-[16/10] items-center justify-center overflow-hidden rounded-sm')}
              >
                <div>{isLoadingMore ? 'Loading more' : 'Load more'}</div>
                {isLoadingMore && (
                  <div className={clsx('ml-3')}>
                    <LoadingDots size={1} />
                  </div>
                )}
              </div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default Websites;
