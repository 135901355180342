'use client';

import WebflowCircle from '@/components/WebflowCircle';
import clsx from 'clsx';
import Link from 'next/link';
import useSWR from 'swr';
import { z } from 'zod';

type WebflowTokenProps = {
  captionOnHover?: boolean;
  websiteId: number;
  className?: string;
  showCaption?: boolean;
};

const responseSchema = z.array(z.coerce.number());

const WebflowToken = ({ captionOnHover = false, websiteId, className = '', showCaption = true }: WebflowTokenProps) => {
  const { data: webflowWebsiteIds } = useSWR(
    '/categories/webflow/websites/ids',
    async (url) => {
      const response = await fetch(url);
      const result = await response.json();
      return responseSchema.parse(result);
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 1000 * 60 * 60, // 1 hour
      dedupingInterval: 1000 * 60 * 60, // 1 hour
    },
  );

  if (!webflowWebsiteIds || !webflowWebsiteIds.includes(websiteId)) {
    return null;
  }

  return (
    <Link
      className={clsx('WebflowToken', 'group flex items-center', className)}
      href={`https://webflow.com/?utm_source=siteinspire&utm_medium=referral&utm_campaign=partnerships`}
      target="_blank"
    >
      <WebflowCircle />

      {showCaption && (
        <div
          className={clsx('hidden whitespace-nowrap pl-2 lg:block', {
            'opacity-0 transition ease-out group-hover:opacity-100': captionOnHover,
          })}
        >
          Made with Webflow
        </div>
      )}
    </Link>
  );
};

export default WebflowToken;
